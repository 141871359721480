<template> 
    <div class="row-container"
        v-if="props.changeType != 'Cost Change between WBS'">
        <h6>{{ $t("Requested cost changes between projects / org units") }}</h6>
        <ODataGrid :data-object="dsBudgetTransfers" ref="bt_grid" hideMenu hideGridMenu :hideMultiselectColumn="true" :hideNewRecords="props.isClosed"
            disableFilterRow>
            <OColumn field="ID" width="80" sortable></OColumn>
            <OColumn editable field="FromOrgUnit" :headerName="$t('From Org Unit')" width="200" sortable v-slot:editor="{ modelValue: row }">
                <OOrgUnitsLookup :bind="sel => { row.FromOrgUnit_ID = sel.ID; row.FromOrgUnit = sel.Name; }">
                    <template #orgunit>
                        <input type="text" v-model="row.FromOrgUnit">
                    </template>
                </OOrgUnitsLookup>
            </OColumn>
            <OColumn editable field="ToOrgUnit" :headerName="$t('To Org Unit')" width="200" sortable v-slot:editor="{ modelValue: row }">
                <OOrgUnitsLookup :bind="sel => { row.ToOrgUnit_ID = sel.ID; row.ToOrgUnit = sel.Name; }">
                    <template #orgunit>
                        <input type="text" v-model="row.ToOrgUnit">
                    </template>
                </OOrgUnitsLookup>
            </OColumn>
            <OColumn editable field="Title" :headerName="$t('Title')" width="200" sortable></OColumn>
            <OColumn editable field="Amount" :headerName="$t('Amount')" width="110" sortable format="1 234.12" class="text-end" summaryAggregate="SUM"></OColumn>
            <OColumn editable field="Description" :headerName="$t('Description')" width="400" sortable></OColumn>
        </ODataGrid>
    </div>
    <div v-if="props.changeType != 'Cost Change between Projects'">
        <h6 class="mt-2">
            {{ $t("Requested cost changes between WBS elements within a project") }}
            <button class="btn btn-link btn-sm" @click="transferEstimate"
                v-if="dsChangeEstimates.data.length > 0 && props.submitted && !dsCostProjects.current.DisableManualTransferOfChangeEstimate && !dsChangeEstimates.current.Transferred">{{
                    $t("Transfer to Cost Estimate") }}</button>
            <span v-if="dsChangeEstimates.data.length > 0 && props.submitted && !dsCostProjects.current.DisableManualTransferOfChangeEstimate && dsChangeEstimates.current.Transferred">({{
                $t("Transferred to Cost Estimate") }}: {{ utils.formatDate(dsChangeEstimates.current.Transferred, 'dd.MM.yyyy') }})</span>
        </h6>
        <ODataGrid :data-object="dsChangeEstimates" ref="bt_grid2" hideMenu hideGridMenu :hideNewRecords="props.isClosed" :hideMultiselectColumn="true"
            disableFilterRow>
            <OColumn field="ID" headerName="ID" width="80" sortable />
            <OColumn field="FromWBS_ID" :headerName="$t('From WBS ID')" width="80" sortable :cellrendererparams="{ href: '/cost-wbsdetails?ID={{FromWBS_ID}}' }" v-slot="{ row, column }" sortField="FromWBS_ID" hide>
                <OLink :modelValue="row" :column="column"></OLink>
            </OColumn>
            <OColumn field="Baseline_ID" :headerName="$t('Baseline_ID')" width="80" sortable hide />
            <OColumn editable field="FromWBSNameAndTitle" :headerName="$t('From WBS')" width="200" sortable>
                <template #default="{ row }">
                    <a v-if="row.Transferred" :href="`/cost-wbs?ID=${row.FromWBS_ID}`" target="_blank">{{ row.FromWBSNameAndTitle }}</a>
                    <span v-else>{{ row.FromWBSNameAndTitle }}</span>
                </template>
                <template #editor="{ row }">
                    <ODataLookup :data-object="dsWBSLkp"
                        :bind="sel => { row.FromWBS_ID = sel.ID; row.FromWBSNameAndTitle = sel.NameAndTitle; }">
                        <template #target="{ target }">
                            <input :ref="target" :value="row.FromWBSNameAndTitle">
                        </template>
                        <o-column field="Name" :headerName="$t('Name')" width="100"></o-column>
                        <o-column field="Title" :headerName="$t('Title')" width="400"></o-column>
                        <o-column field="NamePath" :headerName="$t('NamePath')" width="200"></o-column>
                    </ODataLookup>
                </template>
            </OColumn>
            <OColumn editable field="WBSNameAndTitle" :headerName="$t('To WBS')" width="100" sortable>
                <template #default="{ row }">
                    <a v-if="row.Transferred" :href="`/cost-wbs?ID=${row.WBS_ID}`" target="_blank">{{ row.WBSNameAndTitle }}</a>
                    <span v-else>{{ row.WBSNameAndTitle }}</span>
                </template>
                <template #editor="{ row }">
                    <ODataLookup :data-object="dsWBSLkp"
                        :bind="sel => { row.WBS_ID = sel.ID; row.WBSNameAndTitle = sel.NameAndTitle; }">
                        <template #target="{ target }">
                            <input :ref="target" :value="row.WBSNameAndTitle">
                        </template>
                        <o-column field="Name" :headerName="$t('Name')" width="100"></o-column>
                        <o-column field="Title" :headerName="$t('Title')" width="400"></o-column>
                        <o-column field="NamePath" :headerName="$t('NamePath')" width="200"></o-column>
                    </ODataLookup>
                </template>
            </OColumn>
            <OColumn field="WBS_ID" :headerName="$t('WBS_ID')" width="100" hide :cellrendererparams="{ href: '/cost-wbsdetails?ID={{WBS_ID}}' }"
                v-slot="{ row, column }">
                <OLink :modelValue="row" :column="column"></OLink>
            </OColumn>
            <OColumn editable field="Name" :headerName="$t('Item')" width="100" sortable hide></OColumn>
            <OColumn editable field="Title" :headerName="$t('Title')" width="00" sortable></OColumn>
            <OColumn editable field="CostCode" :headerName="$t('CostCode')" width="160" sortable hide v-slot:editor="{ modelValue: row }">
                <ODataLookup :data-object="dsCostCodes"
                    :bind="sel => { row.CostCode_ID = sel.ID; row.CostCode = sel.Name }">
                    <template #target="{ target }">
                        <input :ref="target" :value="row.CostCode">
                    </template>
                    <o-column field="ID" width="80"></o-column>
                    <o-column field="Name" :headerName="$t('Name')" width="200" v-slot={row}>
                        <span :style="{ paddingLeft: 15 * row.Level + 'px' }" :class="{ 'fw-bold': row.Level == 0 }">{{ row.Name }}</span>
                    </o-column>
                    <o-column field="Title" :headerName="$t('Title')" width="200"></o-column>
                    <o-column field="CostCodeType" :headerName="$t('CostCodeType')" headerName="Type" width="200" hide></o-column>
                    <o-column field="NamePath" :headerName="$t('NamePath')" width="200" hide></o-column>
                </ODataLookup>
            </OColumn>
            <OColumn editable field="Unit" :headerName="$t('Unit')" width="60" sortable v-slot:editor="{ modelValue: row }">
                <ODataLookup :data-object="dsUnitsLkp"
                    :bind="sel => { row.Unit = sel.Name; row.UnitTranslated = sel.Name; }">
                    <template #target="{ target }">
                        <input :ref="target" :value="row.Unit">
                    </template>
                    <o-column field="Name" :headerName="$t('Name')" width="100"></o-column>
                    <o-column field="Description" :headerName="$t('Description')" width="200"></o-column>
                </ODataLookup>
            </OColumn>
            <OColumn editable field="Quantity" :headerName="$t('Quantity')" width="110" sortable format="1 234.1234" class="text-end"></OColumn>
            <OColumn editable field="Rate" :headerName="$t('Rate')" width="110" sortable format="1 234.12" class="text-end"></OColumn>
            <OColumn editable field="VATRate" :headerName="$t('VATRate')" format="0.0%" width="110" sortable class="text-end"></OColumn>
            <OColumn editable field="Estimate" :headerName="$t('Estimate')" width="110" sortable format="1 234.12" class="text-end" summaryAggregate="SUM"></OColumn>
        </ODataGrid>
    </div>
    <div v-if="((props.readOnly && dsChangeMilestones.data.length > 0) || !props.readOnly) && props.submitted">
        <h6 class="mt-2"> {{ $t("Related Milestones") }} </h6>
        <ODataGrid :data-object="dsChangeMilestones" ref="bt_grid3" hideMenu hideGridMenu :hideMultiselectColumn="true"
            disableFilterRow>
            <OColumn editable field="Milestone_ID" :headerName="$t('Milestone_ID')" width="200" sortable
                v-slot:editor="{ modelValue: row }">
                <OMilestonesLookup :bind="sel => { row.Milestone_ID = sel.ID, row.Milestone = sel.Name, row.Title = sel.Title }"
                    v-model="row.Milestone" style="max-height:30px">
                </OMilestonesLookup>
            </OColumn>
            <OColumn field="Milestone" :headerName="$t('Milestone')" width="300"></OColumn>
            <OColumn field="Title" :headerName="$t('Title')" width="300"></OColumn>
            <OColumn field="MilestoneDate" :headerName="$t('MilestoneDate')" width="110"></OColumn>
        </ODataGrid>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getOrCreateProcedure } from 'o365-modules';
import { getOrCreateDataObject } from 'o365-dataobject';
import { OOrgUnitsLookup, OMilestonesLookup } from 'o365-system-lookups';
import { useDataObjectEventListener } from 'o365-vue-utils';
import { alert, confirm } from 'o365-vue-services';
import { utils } from 'o365-utils'; 

const bt_grid = ref(null);
const bt_grid2 = ref(null);
const bt_grid3 = ref(null);

const props = defineProps({
    workflow_id: Number,
    orgunitid: Number,
    changeType: String,
    readOnly: false,
    submitted: false,
    isClosed: false,
});

const dsBudgetTransfers = getOrCreateDataObject({
    id: 'dsBudgetTransfers',
    viewName: 'aviw_Cost_BudgetTransfers',
    maxRecords: 500,
    uniqueTable: 'atbv_Cost_BudgetTransfers',
    allowInsert: true,
    allowDelete: true,
    allowUpdate: true,
    loadRecents: false,
    dynamicLoading: false,
    fields:
        [{ name: "ID", type: "number", sortOrder: 1, sortDirection: "desc" },
        { name: "Workflow_ID", type: "number" },
        { name: "FromOrgUnit_ID", type: "number" },
        { name: "ToOrgUnit_ID", type: "number" },
        { name: "Amount", type: "number" },
        { name: "Title", type: "string" },
        { name: "Description", type: "string" },
        { name: "FromOrgUnit", type: "string" },
        { name: "ToOrgUnit", type: "string" },
        ],
    whereClause: `Workflow_ID=${props.workflow_id}`,
});
dsBudgetTransfers.recordSource.whereClause = `Workflow_ID=${props.workflow_id}`;

const dsChangeEstimates = getOrCreateDataObject({
    id: 'dsChangeEstimates',
    "viewName": 'aviw_Cost_ChangesEstimates',
    "maxRecords": 500,
    "uniqueTable": 'atbv_Cost_ChangesEstimates',
    "allowInsert": true,
    "allowDelete": true,
    "allowUpdate": true,
    "loadRecents": false,
    "dynamicLoading": false,
    "selectFirstRowOnLoad": true,
    "fields":
        [{ name: "ID", type: "number", sortOrder: 1, sortDirection: "desc", },
        { name: "Workflow_ID", type: "number" },
        { name: "Baseline_ID", type: "number" },
        { name: "Name", type: "string" },
        { name: "Title", type: "string" },
        { name: "Quantity", type: "number" },
        { name: "Unit", type: "string" },
        { name: "FromWBS_ID", type: "snumbertring" },
        { name: "FromWBSNameAndTitle", type: "string" },
        { name: "WBS_ID", type: "strnumbering" },
        { name: "WBSNameAndTitle", type: "string" },
        { name: "Rate", type: "number" },
        { name: "VATRate", type: "number" },
        { name: "Estimate", type: "number" },
        { name: "Transferred", type: "date" },
        { name: "CostCode_ID", type: "number" },
        { name: "CostCode", type: "string" },
        ],
    "whereClause": `Workflow_ID=${props.workflow_id}`,
});
dsChangeEstimates.recordSource.whereClause = `Workflow_ID=${props.workflow_id}`;

const dsCostProjects = getOrCreateDataObject({
    id: 'dsCostProjects',
    viewName: 'atbv_Cost_Projects',
    maxRecords: 1,
    allowInsert: false,
    allowDelete: false,
    allowUpdate: false,
    loadRecents: false,
    dynamicLoading: false,
    fields: [
        { name: "ID", type: "number" },
        { name: "DisableManualTransferOfChangeEstimate", type: "bit"}
    ],
    whereClause: `ID=${props.orgunitid}`,
    selectFirstRowOnLoad: true
});

const dsChangeMilestones = getOrCreateDataObject({
    id: 'dsChangeMilestones',
    viewName: 'aviw_Cost_ChangesMilestones',
    maxRecords: 500,
    uniqueTable: 'atbv_Cost_ChangesMilestones',
    allowInsert: true,
    allowDelete: true,
    allowUpdate: true,
    loadRecents: false,
    dynamicLoading: false,
    fields:
        [{ name: "ID", type: "number" },
        { name: "Milestone_ID", type: "number" },
        { name: "Milestone", type: "string", sortOrder: 1, sortDirection: "asc", },
        { name: "Title", type: "string" },
        { name: "MilestoneDate", type: "date" },
        { name: "Workflow_ID", type: "number" },
        ],
    whereClause: `Workflow_ID=${props.workflow_id}`,
});
dsChangeMilestones.recordSource.whereClause = `Workflow_ID=${props.workflow_id}`;

const dsMyCapabilities = getOrCreateDataObject({
    id: 'dsMyCapabilities',
    viewName: 'sviw_System_MyCapabilitiesOrgUnits',
    maxRecords: 1,
    fields: [
        { name: "Capability", type: "string" },
    ],
    whereClause: "Capability = 'Can Edit Transferred Change Estimates'",
});

dsChangeEstimates.on("FieldChanged", (fieldName, value, currentValues, oldValues) => {
    if (dsChangeEstimates.current.isNewRecord && ((fieldName == "WBSNameAndTitle" && dsChangeEstimates.current.WBS_ID) || (fieldName == "FromWBSNameAndTitle" && dsChangeEstimates.current.FromWBS_ID))) {
        defaultEstimates()
    }
});

const defaultEstimates = async () => {
    const procNewEstimateItem = getOrCreateProcedure({ id: "procNewEstimateItem", procedureName: "astp_Cost_WBSNewEstimateItemDouble" });
    const vData = await procNewEstimateItem.execute({
        WBS_ID: dsChangeEstimates.current.WBS_ID,
        FromWBS_ID: dsChangeEstimates.current.FromWBS_ID,
        Workflow_ID: props.workflow_id
    });

    if (vData.Table.length > 0) {

        var vName = dsChangeEstimates.current.Name;

        if (!vName || (!isNaN(vName) && !isNaN(parseFloat(vName)))) {
            dsChangeEstimates.current.Name = vData.Table[0].Name;
        }
        dsChangeEstimates.current.VATRate = dsChangeEstimates.current.VATRate || dsChangeEstimates.current.VATRate === 0 ? dsChangeEstimates.current.VATRate : vData.Table[0].VATRate
    }
}

dsChangeEstimates.on("CurrentIndexChanged", () => {
    setProperEditableChangeEstimates();
});

dsMyCapabilities.on("DataLoaded", () => {
    setProperEditableChangeEstimates();
});

function setProperEditableChangeEstimates() {
    if (dsChangeEstimates.current.Transferred && !dsMyCapabilities.data.find(x => x.Capability === "Can Edit Transferred Change Estimates")) {
        dsChangeEstimates.allowInsert = false;
        dsChangeEstimates.allowUpdate = false;
        dsChangeEstimates.allowDelete = false;
    } else {
        dsChangeEstimates.allowInsert = true;
        dsChangeEstimates.allowUpdate = true;
        dsChangeEstimates.allowDelete = true;
    }
}

const dsWBSLkp = getOrCreateDataObject({
    id: 'dsWBSLkp',
    viewName: 'aviw_Complete_WBS',
    maxRecords: 25,
    loadRecents: false,
    //dynamicLoading: false,
    fields:
        [{ name: "ID", type: "number", },
        { name: "Name", type: "string" },
        { name: "Title", type: "string" },
        { name: "NameAndTitle", type: "string" },
        { name: "NamePath", type: "number", sortOrder: 1, sortDirection: "asc", },
        ],
    whereClause: `HasNodes=0 AND OrgUnit_ID = ${props.orgunitid}`,
});

const dsUnitsLkp = getOrCreateDataObject({
    id: 'dsUnitsLkp',
    viewName: 'sviw_System_Units',
    maxRecords: 25,
    //dynamicLoading: false,
    fields:
        [{ name: "ID", type: "number", sortOrder: 1, sortDirection: "asc" },
        { name: "Name", type: "string" },
        { name: "Description", type: "string" },
        { name: "NameOriginal", type: "string" },
        ],
    whereClause: "Closed IS NULL",
});

const dsCostCodes = getOrCreateDataObject({
    id: 'dsCostCodes',
    viewName: 'aviw_Cost_CostCodes',
    maxRecords: 25,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string"},
        { name: "Title", type: "string"},
        { name: "CostCodeType", type: "string", sortOrder: 1, sortDirection: "asc" },
        { name: "NamePath", type: "string"},
        { name: "IdPath", type: "string", sortOrder: 2, sortDirection: "asc" },
        { name: "Level", type: "string"},
    ],
});

onMounted(() => {
    dsBudgetTransfers.load();
    dsChangeEstimates.load();
    dsChangeMilestones.load();
    dsCostProjects.load();
    dsMyCapabilities.load();

    useDataObjectEventListener(dsBudgetTransfers, "BeforeSave", (pProp, pRow) => {
        pProp.values.Workflow_ID = props.workflow_id
    });
    useDataObjectEventListener(dsChangeEstimates, "BeforeSave", (pProp, pRow) => {
        pProp.values.Workflow_ID = props.workflow_id
    });
    useDataObjectEventListener(dsChangeMilestones, "BeforeSave", (pProp, pRow) => {
        pProp.values.Workflow_ID = props.workflow_id
    });
   
    dsChangeEstimates.on('BeforeSave', (pOptions, pRow) => {
        if(!pRow.WBS_ID){
            alert($t("Field 'To WBS' cannot be blank"), 'info', { autohide: true, delay: 5000});
            pOptions.cancelEvent = true;
        }
    });
});

const procTransfEstToCost = getOrCreateProcedure({ id: "procTransfEstToCost", procedureName: "astp_Workflow_TransferEstimateToCost" });

const transferEstimate = () => {
    confirm({
        title: $t("Transfer to Cost Estimate"),
        message: $t("This will transfer the estimates to the WBS. Please confirm to proceed."),
        btnTextOk: $t("Confirm")
    })
        .then(() => {
            procTransfEstToCost.execute({ Workflow_ID: props.workflow_id })
                .then(() => {
                    dsChangeEstimates.load();
                    alert($t("Estimate transferred to Cost Estimate"), "success");
                })
        })
        .catch(() => { });
}

</script>